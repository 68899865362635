exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-qui-som-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/pages/qui-som/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-qui-som-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-aamon-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/aamon/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-aamon-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-anima-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/anima/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-anima-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-anna-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/anna/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-anna-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-cases-d-algu-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/cases-d-algu/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-cases-d-algu-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-cinema-amb-c-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/cinema-amb-c/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-cinema-amb-c-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-directors-del-desert-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/directors-del-desert/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-directors-del-desert-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-enamigas-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/enamigas/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-enamigas-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-entrecuento-de-navidad-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/entrecuento-de-navidad/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-entrecuento-de-navidad-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-germans-inerts-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/germans-inerts/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-germans-inerts-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-humans-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/humans/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-humans-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-jocs-de-camera-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/jocs-de-camera/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-jocs-de-camera-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-sulfitos-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/sulfitos/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-sulfitos-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-tota-una-vida-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/tota-una-vida/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-tota-una-vida-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-vincles-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/vincles/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-vincles-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-zombiosi-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/Users/tpinel/pantallafinal/content/projects/zombiosi/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-zombiosi-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

